<template>
  <b-dropdown
    variant="info"
    size="sm"
  >
    <template #button-content>
      Action
    </template>
    <b-dropdown-item
      v-if="checkPermission(`show ${slug}`)"
      @click="goToDetail(item.uuid)"
    >
      <feather-icon
        icon="EyeIcon"
        class="mr-75 text-capitalize"
      />
      Detail {{ typeAction }}
    </b-dropdown-item>
    <b-dropdown-item
      v-if="checkPermission(`approve ${slug}`) && item.status == 'pending'"
      @click="approveRejectTopup(item.uuid, 'approve')"
    >
      <feather-icon
        icon="CheckCircleIcon"
        class="mr-75 text-capitalize"
      />
      Approve {{ typeAction }}
    </b-dropdown-item>
    <b-dropdown-item
      v-if="checkPermission(`reject ${slug}`) && item.status == 'pending'"
      @click="approveRejectTopup(item.uuid, 'reject')"
    >
      <feather-icon
        icon="XCircleIcon"
        class="mr-75 text-capitalize"
      />
      Reject {{ typeAction }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    typeAction: {
      type: String,
    },
    slug: {
      type: String,
    },
    item: {
      type: Object,
    },
    getData: {
      type: Function,
    },
    typeWallet: {
      type: String,
    },
    link: {
      type: String,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  methods: {
    approveRejectTopup(uuid, type) {
      this.$emit('loading', true)
      this.$http.post(`admin/wallet/${this.typeWallet}/${this.slug}/${uuid}/${type}`)
        .then(response => {
          this.getData()
          successNotification(this, 'Success', `${this.typeAction} successfully ${type}`)
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    goToDetail(id) {
      this.$router.push({ name: `${this.link}.show`, params: { id } })
    },
  },
}
</script>
